e4.checkout = {
    debug: false,
    init: function () {
        if ( jQuery( '.js-e-checkout' ).length === 1 ) {
            jQuery.when.apply( jQuery, [
                e4.util.require.js( e4.settings.require.handlebars ),
                e4.util.require.js( e4.settings.require.jQueryValidate )
            ] ).then( function () {
                //TODO: Trigger events and delete unused events.
                e4.checkout.event = e4.util.registerEvents( 'created orderupdate orderupdated cartupdate cartupdated alternativedeliverychange alternativedeliverychanged alternativebillingchange alternativebillingchanged deliverychange deliverychanged deliveryaddresschange deliveryaddresschanged stepchange stepchanged countrychange countrychanged', 'checkout' );
                e4.checkout.handlebars.init();
                e4.checkout.create();
            } );
            e4.util.require.js( e4.settings.require.handlebars );
        }
        if ( jQuery( '.js-e-checkout-receipt' ).length === 1 ) {
            e4.util.require.js( e4.settings.require.handlebars, function () {
                //TODO: Trigger events and delete unused events.
                e4.checkout.event = e4.util.registerEvents( 'created orderupdate orderupdated cartupdate cartupdated alternativedeliverychange alternativedeliverychanged alternativebillingchange alternativebillingchanged deliverychange deliverychanged deliveryaddresschange deliveryaddresschanged stepchange stepchanged countrychange countrychanged', 'checkout' );
                e4.checkout.steps.receipt.init();
            } );
        }
    },
    create: function () {
        var jCheckout = jQuery( '.js-e-checkout' ),
            arrCheckoutStepSetup = jCheckout.data( 'checkoutStepSetup' ).split( ',' ),
            strCheckoutType = jCheckout.data( 'checkoutType' );
        e4.checkout.data = {
            steps: {},
            cart: {},
            order: {}
        };
        e4.checkout.states = {
            type: strCheckoutType,
            previousStep: {},
            currentStep: {},
            steps: []
        };
        for ( var i = 0; i < arrCheckoutStepSetup.length; i++ ) {
            e4.checkout.states.steps.push( {
                index: i,
                name: arrCheckoutStepSetup[ i ]
            } );
        }
        e4.checkout.bindEvents();
        if ( e4.util.hash.get( 'step' ) === undefined ) {
            e4.checkout.util.goToStepName( e4.checkout.states.steps[ 0 ].name );
        }
        else {
            jQuery( window ).triggerHandler( 'hashchange' );
        }
        e4.checkout.cart.init();
        jCheckout.trigger( e4.checkout.event.created, { type: e4.checkout.states.type } );
    },
    handlebars: {
        init: function () {
            e4.checkout.handlebars.registerHelpers();
            e4.checkout.handlebars.registerTemplates();
        },
        registerHelpers: function () {
            e4.handlebars.registerHelpers( 'compare counter' );
            Handlebars.registerHelper( 'get_step', function ( name, value ) {
                return e4.checkout.util.getStepByName( name )[ value ];
            } );
            Handlebars.registerHelper( 'get_step_number', function ( name ) {
                return e4.checkout.util.getStepByName( name ).index + 1;
            } );
            Handlebars.registerHelper( 'if_step', function ( name, condition, options ) {
                var step = e4.checkout.util.getStepByName( name );
                return step[ condition ] ? options.fn( this ) : options.inverse( this );
            } );
            Handlebars.registerHelper( 'unless_step', function ( name, condition, options ) {
                var step = e4.checkout.util.getStepByName( name );
                return !step[ condition ] ? options.fn( this ) : options.inverse( this );
            } );
        },
        registerTemplates: function () {
            e4.handlebars.registerTemplates( '#js-e-handlebars-tmpl-checkout #js-e-handlebars-tmpl-order-summery #js-e-handlebars-tmpl-orderlines-summery' );
            e4.handlebars.registerPartials( '#js-e-handlebars-partial-gls #js-e-handlebars-partial-postnord' );
        }
    },
    validationOptions: {},
    util: {
        getStepByIndex: function ( intIndex ) {
            return e4.checkout.states.steps.filter( function ( step ) {
                return step.index === intIndex;
            } )[ 0 ];
        },
        getStepByName: function ( strStepName ) {
            return e4.checkout.states.steps.filter( function ( step ) {
                return step.name === strStepName;
            } )[ 0 ];
        },
        goToStepName: function ( strStepName ) {
            e4.util.hash.set( 'step', strStepName );
        },
        goToNextStep: function () {
            var objCurrentStep = e4.checkout.util.getStepByName( e4.util.hash.get( 'step' ) ),
                jCurrentStepForm = jQuery( '.js-e-checkout-step[data-step-name="' + objCurrentStep.name + '"]' ).find( '.js-e-checkout-step-form' ),
                strNextStepName = e4.checkout.util.getStepByIndex( parseInt( objCurrentStep.index ) + 1 ).name;
            if ( jCurrentStepForm.valid() === true ) {
                e4.checkout.util.goToStepName( strNextStepName );
            }
        },
        mapDeliveryAddressToBillingAddress: function () {
            var objAddressMap = {
                EcomOrderCustomerName: jQuery( '[name="EcomOrderDeliveryName"]' ).val(),
                EcomOrderCustomerCompany: jQuery( '[name="EcomOrderDeliveryCompany"]' ).val(),
                EcomOrderCustomerAddress: jQuery( '[name="EcomOrderDeliveryAddress"]' ).val(),
                EcomOrderCustomerAddress2: jQuery( '[name="EcomOrderDeliveryAddress2"]' ).val(),
                EcomOrderCustomerZip: jQuery( '[name="EcomOrderDeliveryZip"]' ).val(),
                EcomOrderCustomerCity: jQuery( '[name="EcomOrderDeliveryCity"]' ).val(),
                EcomOrderCustomerCountry: jQuery( '[name="EcomOrderDeliveryCountry"]' ).val(),
                EcomOrderCustomerPhone: jQuery( '[name="EcomOrderDeliveryPhone"]' ).val()
            };
            e4.checkout.util.mapAddress( objAddressMap );
        },
        mapBillingAddressToDeliveryAddress: function () {
            var objAddressMap = {
                EcomOrderDeliveryName: jQuery( '[name="EcomOrderCustomerName"]' ).val(),
                EcomOrderDeliveryCompany: jQuery( '[name="EcomOrderCustomerCompany"]' ).val(),
                EcomOrderDeliveryAddress: jQuery( '[name="EcomOrderCustomerAddress"]' ).val(),
                EcomOrderDeliveryAddress2: jQuery( '[name="EcomOrderCustomerAddress2"]' ).val(),
                EcomOrderDeliveryZip: jQuery( '[name="EcomOrderCustomerZip"]' ).val(),
                EcomOrderDeliveryCity: jQuery( '[name="EcomOrderCustomerCity"]' ).val(),
                EcomOrderDeliveryCountry: jQuery( '[name="EcomOrderCustomerCountry"]' ).val(),
                EcomOrderDeliveryPhone: jQuery( '[name="EcomOrderCustomerPhone"]' ).val()
            };
            e4.checkout.util.mapAddress( objAddressMap );
        },
        mapUserAddressToDeliveryAddress: function ( jSelectedAddressData ) {
            var objAddressMap = {
                EcomOrderDeliveryName: jSelectedAddressData.find( '[data-item="EcomOrderDeliveryName"]' ).text(),
                EcomOrderDeliveryCompany: jSelectedAddressData.find( '[data-item="EcomOrderDeliveryCompany"]' ).text(),
                EcomOrderDeliveryAddress: jSelectedAddressData.find( '[data-item="EcomOrderDeliveryAddress"]' ).text(),
                EcomOrderDeliveryAddress2: jSelectedAddressData.find( '[data-item="EcomOrderDeliveryAddress2"]' ).text(),
                EcomOrderDeliveryZip: jSelectedAddressData.find( '[data-item="EcomOrderDeliveryZip"]' ).text(),
                EcomOrderDeliveryCity: jSelectedAddressData.find( '[data-item="EcomOrderDeliveryCity"]' ).text(),
                EcomOrderDeliveryCountry: jSelectedAddressData.find( '[data-item="EcomOrderDeliveryCountry"]' ).text(),
                EcomOrderDeliveryPhone: jSelectedAddressData.find( '[data-item="EcomOrderDeliveryPhone"]' ).text()
            };
            e4.checkout.util.mapAddress( objAddressMap );
        },
        mapAddress: function ( objMapping ) {
            jQuery.each( objMapping, function ( strKey, objValue ) {
                jQuery( '[name="' + strKey + '"]' ).val( objValue );
            } );
        },
        toggleAddressType: function ( domElm ) {
            // Because dom element is passed as argument this function can be used on both shipping and delivery company.
            var jCompanyToggle = jQuery( domElm ),
                jStepForm = jCompanyToggle.closest( '.js-e-checkout-step-form' );
            jStepForm.find( '.js-e-checkout-name-label-toggle' ).toggle();
            jStepForm.find( '.js-e-checkout-company-fieldset' ).slideToggle( e4.settings.animation.speed );
            if ( jCompanyToggle.val() === 'private' ) {
                jStepForm.find( '.js-e-checkout-company' ).val( '' );
            }
        },
        toggleTarget: function ( domElm ) {
            var jToggleField = jQuery( domElm ),
                jTarget = jQuery( '.js-e-checkout-toggle-target[data-target="' + jToggleField.val() + '"]' );
            jTarget.slideToggle( e4.settings.animation.speed );
            if ( jToggleField.is( ':checked' ) === false ) {
                jTarget.find( 'input, textarea' ).val( '' );
            }
        }
    },
    bindEvents: function () {
        /* Bind navigation */
        jQuery( window ).on( 'hashchange', function () {
            if ( e4.util.hash.get( 'step' ) !== undefined ) {
                e4.checkout.steps.changeStep();
            }
        } );
        var jCheckout = jQuery( '.js-e-checkout' );
        /* Espresso events */
        jCheckout.on( e4.checkout.event.orderupdated, function () {
            e4.checkout.steps.bindValidationToCurrentStep();
            e4.checkout.steps.initCurrentStep();
            e4.checkout.steps.scrollCurrentStepIntoView();
        } );
        /* Change events */
        jCheckout.on( 'change', '.js-e-checkout-change-costumer-country', function () {
            e4.checkout.steps.customer.changeCostumerCountry();
        } ).on( 'change', '.js-e-checkout-change-delivery-country', function () {
            e4.checkout.steps.shipping.changeDeliveryCountry();
        } ).on( 'change', '.js-e-checkout-address-type-toggle', function ( event ) {
            event.preventDefault();
            e4.checkout.util.toggleAddressType( event.target );
        } ).on( 'change', '.js-e-checkout-toggle-field', function ( event ) {
            event.preventDefault();
            e4.checkout.util.toggleTarget( event.target );
        } );
        /* Click events */
        jCheckout.on( 'click', '.js-e-checkout-goto-next-step', function ( event ) {
            event.preventDefault();
            e4.checkout.util.goToNextStep();
        } ).on( 'click', '.js-e-checkout-goto-step-name', function ( event ) {
            event.preventDefault();
            var objStep = e4.checkout.util.getStepByName( jQuery( event.target ).data( 'target' ) );
            e4.checkout.util.goToStepName( objStep.name );
        } );
    },
    ajax: function ( objAjaxSettings, fnCallback ) {
        return jQuery.ajax( jQuery.extend( {
            url: e4.settings.ecom.checkout.url,
            method: 'POST',
            dataType: 'json',
            cache: false
        }, objAjaxSettings ) ).done( function ( data ) {
            if ( typeof fnCallback === 'function' ) {
                fnCallback( data );
            }
        } ).fail( function ( jqXHR, textStatus, errorThrown ) {
            console.error( textStatus, jqXHR, errorThrown );
        } );
    },
    cart: {
        init: function () {
            e4.checkout.cart.update();
        },
        update: function () {
            var jCheckout = jQuery( '.js-e-checkout' );
            jCheckout.trigger( e4.checkout.event.cartupdate );
            e4.checkout.ajax( {
                url: e4.settings.ecom.cart.url,
                beforeSend: function () {
                    jQuery( '.js-e-checkout-aside-loading-overlay' ).addClass( 'is-loading' );
                },
                data: jQuery( '.js-e-checkout-step-form' ).serialize()
            }, function ( jsonData ) {
                e4.checkout.data.cart = jsonData;
                jQuery( '.js-e-order-summery' ).html( e4.handlebars.tmpl.orderSummery( e4.checkout.data.cart ) );
                jQuery( '.js-e-orderlines-summery' ).html( e4.handlebars.tmpl.orderlinesSummery( e4.checkout.data.cart ) );
                e4.loading.resolveWait( '.js-e-checkout-aside' );
                jQuery( '.js-e-checkout-aside-loading-overlay' ).removeClass( 'is-loading' );
                jCheckout.trigger( e4.checkout.event.cartupdated );
            } );
        }
    },
    order: {
        timeout: null,
        update: function () {
            var jCheckout = jQuery( '.js-e-checkout' ),
                intStopWatchStart = new Date().getTime();
            jCheckout.trigger( e4.checkout.event.orderupdate );
            return e4.checkout.ajax( {
                beforeSend: function () {
                    e4.checkout.order.timeout = window.clearTimeout( e4.checkout.order.timeout );
                    jQuery( '.js-e-checkout-main-loading-overlay' ).addClass( 'is-loading' );
                },
                data: jQuery( '.js-e-checkout-step-form' ).serialize()
            }, function ( jsonData ) {
                e4.checkout.data.order = jsonData.order;
                e4.checkout.data.steps = jsonData.steps;
                e4.checkout.order.validateShippingMethod();
                e4.checkout.order.validatePaymentMethod();
                jQuery( '#js-e-checkout-steps' ).html( e4.handlebars.tmpl.checkout( e4.checkout.data ) );
                e4.loading.resolveWait( '.js-e-checkout-main' );
                e4.checkout.order.timeout = setTimeout( function () {
                    jQuery( '.js-e-checkout-main-loading-overlay' ).removeClass( 'is-loading' );
                    jCheckout.trigger( e4.checkout.event.orderupdated, e4.checkout.data );
                }, Math.max( 0, e4.settings.ecom.checkout.delay - ( new Date().getTime() - intStopWatchStart ) ) );
            } );
        },
        validateShippingMethod: function () {
            if ( e4.checkout.data.order.shippingmethods.length === 0 ) {
                console.error( 'No available shipping methods for current delivery country: ' + e4.checkout.data.order.delivery.country );
            }
            if ( e4.checkout.data.steps.shipping.shippingMethodId.indexOf( 'SHIP' ) !== 0 ) {
                console.error( 'No shipping method set. Probably missing default method for country: ' + e4.checkout.data.order.delivery.country );
            }
        },
        validatePaymentMethod: function () {
            if ( e4.checkout.data.order.paymentmethods.length === 0 ) {
                console.error( 'No available payment methods for current billing country: ' + e4.checkout.data.order.customer.country );
            }
            if ( e4.checkout.data.steps.payment.paymentMethodId.indexOf( 'PAY' ) !== 0 ) {
                console.error( 'No payment method set. Probably missing default method for country: ' + e4.checkout.data.order.customer.country );
            }
        }
    },
    steps: {
        changeStep: function () {
            var jCheckout = jQuery( '.js-e-checkout' );
            jCheckout.trigger( e4.checkout.event.stepchange, e4.checkout.states.currentStep );
            e4.checkout.steps.setCurrentStep();
            jQuery.when( e4.checkout.order.update() ).then( function () {
                jCheckout.trigger( e4.checkout.event.stepchanged, e4.checkout.states.currentStep );
            }, null );
        },
        setCurrentStep: function () {
            e4.checkout.states.currentStep = e4.checkout.util.getStepByName( e4.util.hash.get( 'step' ) );
            jQuery( '.js-e-checkout-step-current' ).val( e4.checkout.states.currentStep.name );
            for ( var i = 0; i < e4.checkout.states.steps.length; i++ ) {
                if ( i < e4.checkout.states.currentStep.index ) {
                    e4.checkout.states.steps[ i ].isCurrent = false;
                    e4.checkout.states.steps[ i ].isCompleted = true;
                    e4.checkout.states.steps[ i ].isPrev = false;
                }
                else if ( i === e4.checkout.states.currentStep.index ) {
                    e4.checkout.states.steps[ i ].isCurrent = true;
                    e4.checkout.states.steps[ i ].isCompleted = false;
                    e4.checkout.states.steps[ i ].isPrev = false;
                }
                else {
                    e4.checkout.states.steps[ i ].isCurrent = false;
                    e4.checkout.states.steps[ i ].isCompleted = false;
                    e4.checkout.states.steps[ i ].isPrev = false;
                }
            }
        },
        bindValidationToCurrentStep: function () {
            var jCurrentStepForm = jQuery( '.js-e-checkout-step[data-step-name="' + e4.checkout.states.currentStep.name + '"]' ).find( '.js-e-checkout-step-form' );
            jCurrentStepForm.validate( e4.checkout.validationOptions );
        },
        initCurrentStep: function () {
            if ( jQuery.isFunction( e4.checkout.steps[ e4.checkout.states.currentStep.name ].init ) === true ) {
                e4.checkout.steps[ e4.checkout.states.currentStep.name ].init();
            }
        },
        scrollCurrentStepIntoView: function () {
            e4.scrollToAnchor.animate( e4.scrollToAnchor.getTarget( '.js-e-checkout-step[data-step-name="' + e4.checkout.states.currentStep.name + '"]' ), e4.checkout.states.currentStep.index === 1 ? e4.header.hide : jQuery.noop );
        },
        customer: {
            init: function () {
                e4.checkout.steps.customer.bindEvents();
            },
            show: function () {
            },
            bindEvents: function () {
                jQuery( '.js-e-checkout' ).on( 'change', '.js-e-checkout-use-alternative-billing-address', function ( event ) {
                    if ( event.target.value === 'yes' ) {
                        e4.checkout.steps.customer.clear();
                        e4.checkout.steps.customer.showAlternativeBillingAddressForm();
                    }
                    else {
                        e4.checkout.steps.customer.hideAlternativeBillingAddressForm();
                        e4.checkout.util.mapDeliveryAddressToBillingAddress();
                    }
                } ).triggerHandler( 'change' );
            },
            clear: function () {
                var jBillingForm = jQuery( '.js-e-checkout-alternative-billing-address' );
                jBillingForm.find( 'input[type="text"], input[type="number"]' ).val( '' );
                jBillingForm.find( 'input[type="checkbox"]' ).prop( 'checked', false );
                jBillingForm.find( '.js-e-checkout-toggle-target' ).hide();
            },
            showAlternativeBillingAddressForm: function () {
                var strBillingAddressFormSelector = '.js-e-checkout-alternative-billing-address';
                jQuery( strBillingAddressFormSelector ).collapse( 'show' );
                // e4.scrollToAnchor.animate( e4.scrollToAnchor.getTarget( strBillingAddressFormSelector ), e4.header.hide );
            },
            hideAlternativeBillingAddressForm: function () {
                var strBillingAddressFormSelector = '.js-e-checkout-alternative-billing-address';
                jQuery( strBillingAddressFormSelector ).collapse( 'hide' );
            },
            changeCostumerCountry: function () {
                e4.checkout.order.update();
                e4.checkout.cart.update();
            }
        },
        shipping: {
            init: function () {
                e4.checkout.steps.shipping.bindEvents();
                e4.checkout.steps.shipping.userAdresses.init();
                e4.checkout.steps.shipping.gls.init();
                e4.checkout.steps.shipping.postnord.init();
            },
            bindEvents: function () {
                var jCheckout = jQuery( '.js-e-checkout' ).on( 'change', '.js-e-checkout-set-shipping-method', function ( event ) {
                    var strShippingMethodId = jQuery( event.target ).val();
                    e4.checkout.steps.shipping.setShippingMethod( strShippingMethodId );
                } );
                /* User adresses */
                jCheckout.on( 'change', '.js-e-checkout-set-user-address-as-delivery-address', function () {
                    e4.checkout.steps.shipping.userAdresses.set();
                } );
                /* Postnord */
                jCheckout.on( 'keypress', '.js-e-checkout-shippingmethod-postnord-zip-input, .js-e-checkout-shippingmethod-postnord-address-input', function ( event ) {
                    if ( event.which === 13 ) {
                        event.preventDefault();
                        e4.checkout.steps.shipping.postnord.search();
                    }
                } ).on( 'click', '.js-e-checkout-shippingmethod-postnord-search-btn', function () {
                    e4.checkout.steps.shipping.postnord.search();
                } ).on( 'click', '.js-e-checkout-shippingmethod-postnord-result-item', function ( event ) {
                    var strShopId = jQuery( event.target ).val();
                    e4.checkout.steps.shipping.postnord.set( strShopId );
                } );
                /* GLS */
                jCheckout.on( 'keypress', '.js-e-checkout-shippingmethod-gls-zip-input, .js-e-checkout-shippingmethod-gls-address-input', function ( event ) {
                    if ( event.which === 13 ) {
                        event.preventDefault();
                        e4.checkout.steps.shipping.gls.search();
                    }
                } ).on( 'click', '.js-e-checkout-shippingmethod-gls-search-btn', function () {
                    e4.checkout.steps.shipping.gls.search();
                } ).on( 'click', '.js-e-checkout-shippingmethod-gls-result-item', function ( event ) {
                    var strShopId = jQuery( event.target ).val();
                    e4.checkout.steps.shipping.gls.set( strShopId );
                } );
            },
            show: function () {
            },
            clearDeliveryAddress: function () {
                var jDeliveryForm = jQuery( '.js-e-checkout-delivery-address' );
                jDeliveryForm.find( 'input[type="text"], input[type="number"]' ).val( '' );
                jDeliveryForm.find( 'input[type="checkbox"]' ).prop( 'checked', false );
                jDeliveryForm.find( '.js-e-checkout-toggle-target' ).hide();
            },
            setShippingMethod: function ( strShippingMethodId ) {
                if ( strShippingMethodId !== e4.checkout.data.steps.shipping.glsShippingMethodId && jQuery( '.js-e-checkout-shippingmethod-gls-shop-id-input' ).val() !== '' ) {
                    e4.checkout.steps.shipping.gls.clear();
                }
                if ( strShippingMethodId !== e4.checkout.data.steps.shipping.postnordShippingMethodId && jQuery( '.js-e-checkout-shippingmethod-postnord-shop-id-input' ).val() !== '' ) {
                    e4.checkout.steps.shipping.postnord.clear();
                }
                e4.checkout.order.update();
                e4.checkout.cart.update();
            },
            changeDeliveryCountry: function () {
                e4.checkout.order.update();
                e4.checkout.cart.update();
            },
            standard: {
                init: function () {
                },
                show: function () {
                    jQuery( '.js-e-checkout-delivery-address' ).collapse( 'show' );
                },
                hide: function () {
                    jQuery( '.js-e-checkout-delivery-address' ).collapse( 'hide' );
                    e4.checkout.steps.shipping.clearDeliveryAddress();
                }
            },
            userAdresses: {
                init: function () {
                },
                set: function () {
                    var jDeliveryAddressInput = jQuery( '.js-e-checkout-set-user-address-as-delivery-address' );
                    if ( jDeliveryAddressInput.length > 0 ) {
                        var jSelectedAddress = jDeliveryAddressInput.filter( ':checked' );
                        if ( jSelectedAddress.val() === 'alternative' ) {
                            e4.checkout.steps.shipping.clearDeliveryAddress();
                            e4.checkout.steps.shipping.userAdresses.showAlternativeAddress();
                        }
                        else {
                            var jSelectedAddressData = jSelectedAddress.closest( '.js-e-checkout-user-address-item' ).find( '.js-e-checkout-user-address-item-data' );
                            e4.checkout.steps.shipping.userAdresses.hideAlternativeAddress();
                            e4.checkout.steps.shipping.clearDeliveryAddress();
                            e4.checkout.util.mapUserAddressToDeliveryAddress( jSelectedAddressData );
                        }
                        e4.checkout.order.update();
                        e4.checkout.cart.update();
                    }
                },
                showAlternativeAddress: function () {
                    var strDeliveryAddressFormSelector = '.js-e-checkout-delivery-address';
                    jQuery( strDeliveryAddressFormSelector ).collapse( 'show' );
                    // e4.scrollToAnchor.animate( e4.scrollToAnchor.getTarget( strDeliveryAddressFormSelector ), e4.header.hide );
                },
                hideAlternativeAddress: function () {
                    var strDeliveryAddressFormSelector = '.js-e-checkout-delivery-address';
                    jQuery( strDeliveryAddressFormSelector ).collapse( 'hide' );
                }
            },
            postnord: {
                searchQuerySeparator: ' ',
                init: function () {
                    var arrPostnordSearchQuery = e4.checkout.data.order.espressoPostnordSearchQuery.split( e4.checkout.steps.shipping.postnord.searchQuerySeparator );
                    // If we have shop id and search query, we will try to recreate the search and select shop.
                    if ( e4.checkout.data.order.espressoPostnordShopId !== '' && arrPostnordSearchQuery.length === 2 ) {
                        jQuery( '.js-e-checkout-shippingmethod-postnord-zip-input' ).val( arrPostnordSearchQuery[ 0 ] );
                        jQuery( '.js-e-checkout-shippingmethod-postnord-address-input' ).val( arrPostnordSearchQuery[ 1 ] );
                        e4.checkout.steps.shipping.postnord.search();
                    }
                },
                clear: function () {
                    e4.checkout.steps.shipping.postnord.data = null;
                    e4.checkout.steps.shipping.clearDeliveryAddress();
                    jQuery( '.js-e-checkout-shippingmethod-postnord-results' ).html( '' );
                    jQuery( '.js-e-checkout-shippingmethod-postnord-shop-id-input' ).val( '' );
                    jQuery( '.js-e-checkout-shippingmethod-postnord-search-query-input' ).val( '' );
                    jQuery( '.js-e-checkout-shippingmethod-postnord-zip-input' ).data( 'rule-required', true );
                },
                set: function ( strShopId ) {
                    for ( var i = 0; i < e4.checkout.steps.shipping.postnord.data.servicePoints.length; i++ ) {
                        e4.checkout.steps.shipping.postnord.data.servicePoints[ i ].isSelected = false;
                        if ( e4.checkout.steps.shipping.postnord.data.servicePoints[ i ].servicePointId === strShopId ) {
                            // Save shop address details to use as delivery address.
                            var objAddressMap = {
                                EcomOrderDeliveryName: e4.checkout.steps.shipping.postnord.data.servicePoints[ i ].name,
                                EcomOrderDeliveryCompany: e4.checkout.steps.shipping.postnord.data.servicePoints[ i ].name,
                                EcomOrderDeliveryAddress: e4.checkout.steps.shipping.postnord.data.servicePoints[ i ].deliveryAddress.streetName + ' ' + e4.checkout.steps.shipping.postnord.data.servicePoints[ i ].deliveryAddress.streetNumber,
                                EcomOrderDeliveryZip: e4.checkout.steps.shipping.postnord.data.servicePoints[ i ].deliveryAddress.postalCode,
                                EcomOrderDeliveryCity: e4.checkout.steps.shipping.postnord.data.servicePoints[ i ].deliveryAddress.city
                            };
                            // Mark shop as selected.
                            e4.checkout.steps.shipping.postnord.data.servicePoints[ i ].isSelected = true;
                            e4.checkout.steps.shipping.clearDeliveryAddress();
                            e4.checkout.util.mapAddress( objAddressMap );
                            jQuery( '.js-e-checkout-shippingmethod-postnord-shop-id-input' ).val( e4.checkout.steps.shipping.postnord.data.servicePoints[ i ].servicePointId );
                        }
                        jQuery( '.js-e-checkout-shippingmethod-postnord-zip-input' ).data( 'rule-required', false );
                    }
                },
                search: function () {
                    var strPostnordZip = jQuery( '.js-e-checkout-shippingmethod-postnord-zip-input' ).val(),
                        strPostnordAddress = jQuery( '.js-e-checkout-shippingmethod-postnord-address-input' ).val(),
                        jLoadingMain = jQuery( '.js-e-checkout-main-loading-overlay' );
                    e4.checkout.ajax( {
                        method: 'GET',
                        cache: true,
                        url: '/pnlookup.ashx',
                        beforeSend: function () {
                            // Save search parameters to be able to recreate the search.
                            jQuery( '.js-e-checkout-shippingmethod-postnord-search-query-input' ).val( [
                                strPostnordZip,
                                e4.checkout.steps.shipping.postnord.searchQuerySeparator,
                                strPostnordAddress
                            ].join( '' ) );
                            jLoadingMain.addClass( 'is-loading' );
                        },
                        data: {
                            countryCode: jQuery( '#EcomOrderDeliveryCountry' ).val(),
                            numberOfServicePoints: e4.settings.ecom.checkout.shipping.searchCount,
                            postalCode: strPostnordZip,
                            streetName: strPostnordAddress
                        }
                    }, function ( jsonData ) {
                        e4.checkout.steps.shipping.postnord.data = jsonData.servicePointInformationResponse || null;
                        if ( e4.checkout.steps.shipping.postnord.data !== null ) {
                            // Loop through all shops and check if the currently selected shop is in the results and mark it as selected.
                            for ( var i = 0; i < e4.checkout.steps.shipping.postnord.data.servicePoints.length; i++ ) {
                                e4.checkout.steps.shipping.postnord.data.servicePoints[ i ].isSelected = e4.checkout.steps.shipping.postnord.data.servicePoints[ i ].servicePointId === e4.checkout.data.order.espressoPostnordShopId;
                            }
                        }
                        e4.checkout.steps.shipping.postnord.build();
                        jLoadingMain.removeClass( 'is-loading' );
                    } );
                },
                build: function () {
                    jQuery( '#js-e-checkout-shippingmethod-postnord-results' ).html( e4.handlebars.partial.postnord( e4.checkout.steps.shipping.postnord.data ) ).collapse( 'show' );
                    e4.scrollToAnchor.animate( e4.scrollToAnchor.getTarget( '.js-e-checkout-shippingmethod-postnord' ), e4.header.hide );
                }
            },
            gls: {
                searchQuerySeparator: ' ',
                init: function () {
                    var arrGlsSearchQuery = e4.checkout.data.order.espressoGlsSearchQuery.split( e4.checkout.steps.shipping.gls.searchQuerySeparator );
                    // If we have shop id and search query, we will try to recreate the search and select shop.
                    if ( e4.checkout.data.order.espressoGlsShopId !== '' && arrGlsSearchQuery.length === 2 ) {
                        jQuery( '.js-e-checkout-shippingmethod-gls-zip-input' ).val( arrGlsSearchQuery[ 0 ] );
                        jQuery( '.js-e-checkout-shippingmethod-gls-address-input' ).val( arrGlsSearchQuery[ 1 ] );
                        e4.checkout.steps.shipping.gls.search();
                    }
                },
                clear: function () {
                    e4.checkout.steps.shipping.clearDeliveryAddress();
                    jQuery( '.js-e-checkout-shippingmethod-gls-shop-id-input' ).val( '' );
                    jQuery( '.js-e-checkout-shippingmethod-gls-search-query-input' ).val( '' );
                    e4.checkout.steps.shipping.gls.data = null;
                    jQuery( '.js-e-checkout-shippingmethod-gls-zip-input' ).data( 'rule-required', true );
                    jQuery( '.js-e-checkout-shippingmethod-gls-results' ).collapse( 'hide' );
                },
                set: function ( strShopId ) {
                    for ( var i = 0; i < e4.checkout.steps.shipping.gls.data.parcelshops.length; i++ ) {
                        e4.checkout.steps.shipping.gls.data.parcelshops[ i ].isSelected = false;
                        if ( e4.checkout.steps.shipping.gls.data.parcelshops[ i ].Number === strShopId ) {
                            var objAddressMap = {
                                EcomOrderDeliveryName: e4.checkout.steps.shipping.gls.data.parcelshops[ i ].CompanyName,
                                EcomOrderDeliveryCompany: e4.checkout.steps.shipping.gls.data.parcelshops[ i ].Streetname2,
                                EcomOrderDeliveryAddress: e4.checkout.steps.shipping.gls.data.parcelshops[ i ].Streetname,
                                EcomOrderDeliveryZip: e4.checkout.steps.shipping.gls.data.parcelshops[ i ].ZipCode,
                                EcomOrderDeliveryCity: e4.checkout.steps.shipping.gls.data.parcelshops[ i ].CityName
                            };
                            e4.checkout.steps.shipping.gls.data.parcelshops[ i ].isSelected = true;
                            e4.checkout.steps.shipping.clearDeliveryAddress();
                            e4.checkout.util.mapAddress( objAddressMap );
                            jQuery( '.js-e-checkout-shippingmethod-gls-shop-id-input' ).val( e4.checkout.steps.shipping.gls.data.parcelshops[ i ].Number );
                        }
                        jQuery( '.js-e-checkout-shippingmethod-gls-zip-input' ).data( 'rule-required', false );
                    }
                },
                search: function () {
                    var strGlsZip = jQuery( '.js-e-checkout-shippingmethod-gls-zip-input' ).val(),
                        strGlsAddress = jQuery( '.js-e-checkout-shippingmethod-gls-address-input' ).val(),
                        jLoadingMain = jQuery( '.js-e-checkout-main-loading-overlay' );
                    e4.checkout.ajax( {
                        method: 'GET',
                        cache: true,
                        url: '/glslookup.ashx',
                        beforeSend: function () {
                            // Save search parameters to be able to recreate the search.
                            jQuery( '.js-e-checkout-shippingmethod-gls-search-query-input' ).val( [
                                strGlsZip,
                                e4.checkout.steps.shipping.gls.searchQuerySeparator,
                                strGlsAddress
                            ].join( '' ) );
                            jLoadingMain.addClass( 'is-loading' );
                        },
                        data: {
                            count: e4.settings.ecom.checkout.shipping.searchCount,
                            type: strGlsAddress !== '' ? 'ADDRESS' : 'ZIP',
                            street: strGlsAddress,
                            zipcode: strGlsZip
                        }
                    }, function ( jsonData ) {
                        e4.checkout.steps.shipping.gls.data = jsonData;
                        // Loop through all shops and check if the currently selected shop is in the results and mark it as selected.
                        for ( var i = 0; i < e4.checkout.steps.shipping.gls.data.parcelshops.length; i++ ) {
                            e4.checkout.steps.shipping.gls.data.parcelshops[ i ].isSelected = e4.checkout.steps.shipping.gls.data.parcelshops[ i ].Number === e4.checkout.data.order.espressoGlsShopId;
                        }
                        e4.checkout.steps.shipping.gls.build();
                        jLoadingMain.removeClass( 'is-loading' );
                    } );
                },
                build: function () {
                    jQuery( '#js-e-checkout-shippingmethod-gls-results' ).html( e4.handlebars.partial.gls( e4.checkout.steps.shipping.gls.data ) ).collapse( 'show' );
                    e4.scrollToAnchor.animate( e4.scrollToAnchor.getTarget( '.js-e-checkout-shippingmethod-gls' ), e4.header.hide );
                }
            }
        },
        payment: {
            // OBS: The payment method does not always have a step of its own. It can be a part of an other step. In B2B checkout it is often a part of the customer step. The functions will run regardless of where the payment method controls are placed.
            init: function () {
                e4.checkout.steps.payment.bindEvents();
            },
            bindEvents: function () {
                jQuery( '.js-e-checkout' ).on( 'change', '.js-e-checkout-set-payment-method', function () {
                    e4.checkout.steps.payment.setPaymentMethod();
                } );
            },
            show: function () {
            },
            setPaymentMethod: function () {
                e4.checkout.order.update();
                e4.checkout.cart.update();
            }
        },
        approve: {
            init: function () {
                e4.checkout.steps.approve.bindEvents();
                jQuery( '.js-e-checkout-step-summary-data' ).addClass( 'e-checkout-step-summary-data-approve' );
                e4.checkout.steps.approve.loadTermsAndConditions();
            },
            bindEvents: function () {
                jQuery( '.js-e-checkout-accept-terms-and-conditions-btn' ).on( 'click', function () {
                    jQuery( 'input[name="EcomOrderCustomerAccepted"]' ).prop( 'checked', true );
                } );
            },
            show: function () {
            },
            loadTermsAndConditions: function () {
                e4.checkout.ajax( {
                    method: 'GET',
                    cache: true,
                    url: e4.settings.ecom.checkout.termsAndConditions,
                    dataType: 'html'
                }, function ( strHtmlDocument ) {
                    var strHtml = jQuery( strHtmlDocument ).find( '.js-e-content' ).html();
                    jQuery( '.js-e-checkout-modal-terms-and-conditions' ).replaceWith( strHtml );
                } );
            }
        },
        receipt: {
            init: function () {
                e4.util.hash.remove( 'step' );
                e4.handlebars.registerTemplates( '#js-e-handlebars-tmpl-order-summery #js-e-handlebars-tmpl-orderlines-summery' );
                e4.checkout.steps.receipt.load();
            },
            load: function () {
                var jCheckout = jQuery( '.js-e-checkout' ),
                    objOrderData = jQuery( '.js-e-checkout-receipt' ).data();
                jCheckout.trigger( e4.checkout.event.receiptshow );
                e4.checkout.ajax( {
                    method: 'GET',
                    url: e4.settings.ecom.cart.url,
                    data: objOrderData
                }, function ( jsonData ) {
                    e4.loading.resolveWait( '.js-e-checkout-aside' );
                    e4.checkout.steps.receipt.build( jsonData );
                    jCheckout.trigger( e4.checkout.event.receiptshown, {
                        index: 5, // TODO: Spider sense - always 5?
                        name: 'receipt'
                    } );
                } );
            },
            build: function ( jsonData ) {
                jQuery( '.js-e-order-summery' ).html( e4.handlebars.tmpl.orderSummery( jsonData ) );
                jQuery( '.js-e-orderlines-summery' ).html( e4.handlebars.tmpl.orderlinesSummery( jsonData ) );
            }
        }
    }
};
