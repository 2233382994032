var vc = vc || {};
( function () {
    'use strict';
    jQuery( function () {
        vc.anchorLinks.init();
    } );

    vc.anchorLinks = {
        init: function () {
            var currentPageUrl = jQuery( location ).attr( 'href' ).split( '#' );
            jQuery( '.js-vc-anchor-link' ).each( function() {
                var anchorLinkId = jQuery( this ).attr( 'href' ).split( '#' );
                jQuery( this ).attr( 'href', currentPageUrl[0] + '#' + anchorLinkId[1]); 
            });
        }
    };
    
    
} )(); 
